@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Mulish';
  font-weight: 500;
  src: local('Mulish-Regular'),
       url('assets/fonts/Mulish-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Mulish';
  font-weight: 700;
  src: local('Mulish-Bold'),
       url('assets/fonts/Mulish-Bold.ttf') format('truetype')
}

body {
  background: #02050F;
  color: #E1E1E2;
  font-family: Mulish;
}

a {
  color: #56CCF2;
  transition: 300ms;
}

a:hover {
  color: #56CCF2;
}

h1 {
  @apply text-xl font-bold;
}

ul {
  @apply list-disc [&>li]:ml-4 [&>li]:mb-2;
}
